var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"marginWrapper el-row--padding"},[_c('div',{staticClass:"flex flexColumn"},[_c('el-row',[_c('el-col',[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"flex verticleCentering spaceBetween",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("GPS Service Provider Registration Form")]),_c('div',{staticClass:"textRight"},[_c('el-button',{attrs:{"type":"primary el-button--mini","plain":"","round":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('i',{staticClass:"el-icon-d-arrow-left"}),_vm._v("Back ")])],1)]),(_vm.gpsProvider.profile)?_c('el-form',{attrs:{"label-position":'left',"label-width":"160px","enctype":"multipart/form-data","multiple":""}},[_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"10px"}},[_vm._v(" GPS Service Provider Information ")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Company Name'),
                      'is-required': _vm.isFieldRequired('Company Name'),
                    },attrs:{"label":"Company Name"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Company Name","name":"Company Name"},model:{value:(_vm.gpsProvider.profile.companyName),callback:function ($$v) {_vm.$set(_vm.gpsProvider.profile, "companyName", $$v)},expression:"gpsProvider.profile.companyName"}}),(_vm.errors.has('Company Name'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Company Name")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Email'),
                      'is-required': _vm.isFieldRequired('Email'),
                    },attrs:{"label":"Email"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Email","name":"Email"},model:{value:(_vm.gpsProvider.email),callback:function ($$v) {_vm.$set(_vm.gpsProvider, "email", $$v)},expression:"gpsProvider.email"}}),(_vm.errors.has('Email'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Email")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12,"hidden":""}},[_c('el-form-item',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gpsProvider.gpsServiceProvider.isActive),expression:"gpsProvider.gpsServiceProvider.isActive"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.gpsProvider.gpsServiceProvider.isActive)?_vm._i(_vm.gpsProvider.gpsServiceProvider.isActive,null)>-1:(_vm.gpsProvider.gpsServiceProvider.isActive)},on:{"change":function($event){var $$a=_vm.gpsProvider.gpsServiceProvider.isActive,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.gpsProvider.gpsServiceProvider, "isActive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.gpsProvider.gpsServiceProvider, "isActive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.gpsProvider.gpsServiceProvider, "isActive", $$c)}}}}),_vm._v(" Is Active ")])],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Registration Date'),
                      'is-required': _vm.isFieldRequired('Registration Date'),
                    },attrs:{"label":"Registration Date"}},[_c('el-date-picker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"type":"date","placeholder":"Registration Date","value-format":"yyyy-MM-dd","name":"Registration Date"},model:{value:(
                        _vm.gpsProvider.gpsServiceProvider.registrationDate
                      ),callback:function ($$v) {_vm.$set(_vm.gpsProvider.gpsServiceProvider, "registrationDate", $$v)},expression:"\n                        gpsProvider.gpsServiceProvider.registrationDate\n                      "}}),_c('br'),(_vm.errors.has('Registration Date'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Registration Date")))]):_vm._e()],1)],1)],1)],1),_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"10px"}},[_vm._v(" Contact Information ")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Contact Person'),
                      'is-required': _vm.isFieldRequired('Contact Person'),
                    },attrs:{"label":"Contact Person"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Contact Person","name":"Contact Person"},model:{value:(_vm.gpsProvider.gpsServiceProvider.contactPerson),callback:function ($$v) {_vm.$set(_vm.gpsProvider.gpsServiceProvider, "contactPerson", $$v)},expression:"gpsProvider.gpsServiceProvider.contactPerson"}}),(_vm.errors.has('Contact Person'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Contact Person")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Mobile No'),
                      'is-required': _vm.isFieldRequired('Mobile No'),
                    },attrs:{"label":"Mobile No"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|mobileNo'),expression:"'required|mobileNo'"}],attrs:{"placeholder":"Mobile No","maxlength":"10","name":"Mobile No"},model:{value:(
                        _vm.gpsProvider.gpsServiceProvider.personalContactNumber
                      ),callback:function ($$v) {_vm.$set(_vm.gpsProvider.gpsServiceProvider, "personalContactNumber", $$v)},expression:"\n                        gpsProvider.gpsServiceProvider.personalContactNumber\n                      "}}),(_vm.errors.has('Mobile No'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Mobile No")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Office Contact No'),
                      'is-required': _vm.isFieldRequired('Office Contact No'),
                    },attrs:{"label":"Office No"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Office No","name":"Office Contact No","maxlength":"10"},model:{value:(
                        _vm.gpsProvider.gpsServiceProvider.officeContactNumber
                      ),callback:function ($$v) {_vm.$set(_vm.gpsProvider.gpsServiceProvider, "officeContactNumber", $$v)},expression:"\n                        gpsProvider.gpsServiceProvider.officeContactNumber\n                      "}}),(_vm.errors.has('Office Contact No'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Office Contact No")))]):_vm._e()],1)],1)],1)],1),_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"10px"}},[_vm._v(" Permanent Address ")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Province'),
                      'is-required': _vm.isFieldRequired('Province'),
                    },attrs:{"label":"Province"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select Province","name":"Province","filterable":""},on:{"change":_vm.onProvinceChange},model:{value:(_vm.gpsProvider.profile.province),callback:function ($$v) {_vm.$set(_vm.gpsProvider.profile, "province", $$v)},expression:"gpsProvider.profile.province"}},_vm._l((_vm.province),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('Province'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Province")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('District'),
                      'is-required': _vm.isFieldRequired('District'),
                    },attrs:{"label":"District"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select District","name":"District","filterable":""},on:{"change":_vm.onDistrictChange},model:{value:(_vm.gpsProvider.profile.district),callback:function ($$v) {_vm.$set(_vm.gpsProvider.profile, "district", $$v)},expression:"gpsProvider.profile.district"}},_vm._l((_vm.districts),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('District'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("District")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Palika'),
                      'is-required': _vm.isFieldRequired('Palika'),
                    },attrs:{"label":"Municipality"}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Select Palika","name":"Palika","filterable":""},model:{value:(_vm.gpsProvider.profile.palika),callback:function ($$v) {_vm.$set(_vm.gpsProvider.profile, "palika", $$v)},expression:"gpsProvider.profile.palika"}},_vm._l((_vm.municipality),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('br'),(_vm.errors.has('Palika'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Palika")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Ward Number'),
                      'is-required': _vm.isFieldRequired('Ward Number'),
                    },attrs:{"label":"Ward Number"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Ward Number","name":"Ward Number","maxlength":"2"},model:{value:(_vm.gpsProvider.profile.wardNo),callback:function ($$v) {_vm.$set(_vm.gpsProvider.profile, "wardNo", $$v)},expression:"gpsProvider.profile.wardNo"}}),(_vm.errors.has('Ward Number'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Ward Number")))]):_vm._e()],1)],1)],1),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Tole'),
                      'is-required': _vm.isFieldRequired('Tole'),
                    },attrs:{"label":"Tole/Street"}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"placeholder":"Tole/Street","name":"Tole"},model:{value:(_vm.gpsProvider.profile.tole),callback:function ($$v) {_vm.$set(_vm.gpsProvider.profile, "tole", $$v)},expression:"gpsProvider.profile.tole"}}),(_vm.errors.has('Tole'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Tole")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Block Number'),
                      'is-required': _vm.isFieldRequired('Block Number'),
                    },attrs:{"label":"Block Number"}},[_c('el-input',{attrs:{"placeholder":"Block Number","name":"Block Number"},model:{value:(_vm.gpsProvider.profile.blockNo),callback:function ($$v) {_vm.$set(_vm.gpsProvider.profile, "blockNo", $$v)},expression:"gpsProvider.profile.blockNo"}}),(_vm.errors.has('Block Number'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Block Number")))]):_vm._e()],1)],1)],1)],1),_c('el-row',[_c('h4',{staticStyle:{"margin-left":"20px","margin-bottom":"10px"}},[_vm._v(" Suportive Documents ")]),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('Company Certificate'),
                      'is-required': _vm.isFieldRequired('Company Certificate'),
                    },attrs:{"label":"Company Registration"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","id":"doc","auto-upload":false,"on-change":_vm.handleChange,"on-remove":_vm.handleRemove,"file-list":_vm.companyCertificate,"accept":"image/*","thumbnail-mode":""}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({ required: _vm.isFileRequired, image: true }),expression:"{ required: isFileRequired, image: true }"}],key:"Company Certificate",attrs:{"slot":"trigger","name":"Company Certificate","size":"small","type":"primary","round":""},slot:"trigger",model:{value:(_vm.companyCertificate),callback:function ($$v) {_vm.companyCertificate=$$v},expression:"companyCertificate"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),(_vm.errors.has('Company Certificate'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("Company Certificate")))]):_vm._e(),_c('detail-tag',{attrs:{"data":_vm.gpsProvider.gpsServiceProvider.companyCertificateName,"dataLink":_vm.gpsProvider.gpsServiceProvider.companyCertificate,"type":"editimage"}}),(_vm.errors.has(''))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("")))]):_vm._e()],1)],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{class:{
                      'is-error': _vm.fieldHasErrors('PAN/VAT Certificate'),
                      'is-required': _vm.isFieldRequired('PAN/VAT Certificate'),
                    },attrs:{"label":"PAN/VAT Certificate"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"","id":"doc","auto-upload":false,"on-change":_vm.handleChange1,"on-remove":_vm.handleRemove1,"file-list":_vm.panCertificate,"accept":"image/*","thumbnail-mode":""}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({ required: _vm.isFileRequired, image: true }),expression:"{ required: isFileRequired, image: true }"}],key:"PAN/VAT Certificate",attrs:{"slot":"trigger","name":"PAN/VAT Certificate","size":"small","type":"primary","round":""},slot:"trigger",model:{value:(_vm.panCertificate),callback:function ($$v) {_vm.panCertificate=$$v},expression:"panCertificate"}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v("  Click to upload ")])],1),(_vm.errors.has('PAN/VAT Certificate'))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("PAN/VAT Certificate")))]):_vm._e(),_c('detail-tag',{attrs:{"data":_vm.gpsProvider.gpsServiceProvider.panCertificateName,"dataLink":_vm.gpsProvider.gpsServiceProvider.panCertificate,"type":"editimage"}}),(_vm.errors.has(''))?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.errors.first("")))]):_vm._e()],1)],1)],1)],1),_c('el-row',[_c('el-col',[_c('el-form-item',{staticClass:"textRight"},[_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":_vm.submit}},[_vm._v("Save")]),_c('el-button',{attrs:{"type":"danger","round":""},on:{"click":_vm.cancelForm}},[_vm._v("Cancel")])],1)],1)],1)],1):_vm._e()],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }